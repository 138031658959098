import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`The first thing you notice about them is their eyes.  The iris seems to take on a life of it’s own, shifting an shimmering as if there’s something there, like the glint of fire or the crashing waves of the sea. The next, of course, is the hair.`}</em></p>
    <div style={{
      "textAlign": "right"
    }}>—Samat Nazerov, <em>The Color of the Spirits</em></div>
    <p>{`Scions are chosen by a `}<a parentName="p" {...{
        "href": "/Primal%20Spirits",
        "title": "Primal Spirits"
      }}>{`Primal Spirit`}</a>{`, completing an implicit `}<a parentName="p" {...{
        "href": "/The%20Exchange",
        "title": "The Exchange"
      }}>{`Exchange`}</a>{` to bind the Scion to a particular Primal Spirit at birth.`}</p>
    <h2>{`Notable Scions`}</h2>
    <p>{``}<a parentName="p" {...{
        "href": "/Rysha%20Tan%20Mei",
        "title": "Rysha Tan Mei"
      }}>{`Rysha Tan Mei`}</a>{`, a Scion of the `}<a parentName="p" {...{
        "href": "/Primal%20Spirit%20of%20Water",
        "title": "Primal Spirit of Water"
      }}>{`Primal Spirit of Water`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      